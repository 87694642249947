import React from "react";
import { Link } from 'gatsby';



const GreenCard = ({ title, description, href, additionalClass }) => {

  return (

    <Link className="green__card" to={href} target={href.includes('/energy-performance-certificate') ? "_self" : "_blank"} >
      <div className="green__card__text">
        <Link className="homepage-link-titles" to={href} >
          <h2 className="section__title">{title}</h2>
        </Link>
        <p className={`description ${additionalClass}`}>
          {description}
        </p>
      </div>
      <span><svg width="11" height="18" viewBox="0 0 11 18" fill="red"><path d="M10.5 0.207153L2.5 8.87506L10.5 17.543H8L0 8.87506L8 0.207153H10.5Z" fill="red"></path></svg></span>
    </Link>
  );
};

export default GreenCard;

import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import Layout from '../components/layout';
import EpcHero from '../assets/images/epc_hero.png';
import GreenCard from '../components/green-page/green-card.component';
import NewWindow from '../assets/new_window.inline.svg';
import "../styles/app.scss"


export default function EPC() {
  const location = useLocation();

  useEffect(() => {
    const isGreenPage = location.pathname.includes('energy-performance-certificate');
    const elem = document.querySelector('.page');

    if (elem) {
      if (isGreenPage) {
        elem.classList.add('page--grey');
      } else {
        elem.classList.remove('page--grey');
      }
    }


  }, [location]);

  return (
    <Layout
      title='Energy performance certificate'
      metaDescription="HSBC Intermediaries Energy performance certificate Page"
    >
      <div className="epc green row">
        <div className='epc__hero'>
          <div className="epc__hero--left">
            <div className='text-section'>
              <h2>Energy performance certificate</h2>
              <p>Did you know? The UK government has proposed changes to the Minimum Energy Efficiency Standards for England and Wales, which will require all rental properties to have an EPC rating of "C" or above by 2025.
                <br /><br />
                The changes will be introduced first for new tenancies, followed by all tenancies from 2028. The penalty for not having a valid EPC will be raised from £5,000 to £30,000 from 2025. The changes aim to reduce carbon emissions and make homes more energy-efficient.
                <br /><br />
                Source - GOV.UK: <a target='_blank' href='https://www.gov.uk/guidance/domestic-private-rented-property-minimum-energy-efficiency-standard-landlord-guidance' style={{ textDecoration: "underline" }}>Minimum energy efficiency standard - landlord guidance</a><a target='_blank' href='https://www.gov.uk/guidance/domestic-private-rented-property-minimum-energy-efficiency-standard-landlord-guidance' style={{ textDecoration: "none" }}>  <NewWindow style={{ marginBottom: "-3px" }} /></a>
              </p>

            </div>

          </div>
          <div className="epc__hero--right">
            <img src={EpcHero} alt="Hero for Energy Performance Certificate" />
          </div>
        </div>
        <div className="green__card-container">

          <div className="green__card epc__custom-card">
            <div className="green__card__text">
              <h2 className="section__title">Your customers energy performance certificate</h2>
              <p className='description'>Government EPC registers</p>
              <a className="homepage-link-titles display-block" href="https://www.gov.uk/find-energy-certificate" target='_blank' >
                <p className="section__title">England, Wales, Northern Ireland </p>
              </a>
              <a className="homepage-link-titles display-block" href="https://www.scottishepcregister.org.uk/" target='_blank' >
                <p className="section__title">Scotland</p>
              </a>
            </div>
          </div>


          <GreenCard href="https://www.hsbc.co.uk/mortgages/can-a-low-epc-rating-affect-your-mortgage-application/" title="Can a low EPC rating affect your mortgage application?" description="Find out why Energy Performance Certificate ratings are important when buying a home." additionalClass="padding-right-20" />
        </div>
      </div>
    </Layout>

  );
}
